export const fullPrivacy = [
    {
        title: "Who are we?",
        description: "We are **United Legal Assistance Limited (‘ULA’)** and can be contacted on the below contact details:"
    },
    {
        itemList:
            [
                {
                    description: '＞ Email: please use [contact us](/contact-us) page'
                },
                {
                    description: '＞ Telephone: [01704 468030](tel:01704468030)'
                },
                {
                    description: '＞ Address: 130C Lord Street, Southport, PR9 0AE'
                },
            ]
    },
    {
        title: "Definitions and Interpretation",
        description: "In this Policy the following terms shall have the following meanings:"
    },
    {
        itemList:
            [
                {
                    description: '**“Accident Management Service”** means the range of accident management services we offer to clients'
                },
                {
                    description: '**“Data”** means collectively all information that you submit to United Legal Assistance via the Website. This definition shall, where applicable, incorporate the definitions provided in the Data Protection Act 1998;'
                },
                {
                    description: '**“Cookie”** means a small text file placed on your computer by this Website when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in Clause 12;'
                },
                {
                    description: '**“GDPR”** means Regulation (EU) 2016/679 (General Data Protection Regulation);'
                },
                {
                    description: '**“ULA”** means the company United Legal Assistance Limited authorised and regulated by The Financial Conduct Authority, FRN 629948; Company Registered number: 08934259'
                },
                {
                    description: '**“UK and EU Cookie Law”** means the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011;'
                },
                {
                    description: '**“User”** means any third party that accesses the Website and is not employed by **United Legal Assistance** and acting in the course of their employment;'
                },
                {
                    description: '**“Website”** means the website that you are currently using **(www.united-legal.co.uk)** and any sub-domains of this site (e.g. subdomain.www.united-legal.co.uk) unless expressly excluded by their own terms and conditions.'
                },
            ]
    },
    {
        title: "What Personal Information do we collect from you?",
        description: "Without limitation, any of the following Data may be collected by this Website from time to time:"
    },
    {
        itemList:
            [
                {
                    description: '＞ Name and address'
                },
                {
                    description: '＞ Phone number and e-mail address'
                },
                {
                    description: '＞ Details about the services that you specifically requested'
                },
                {
                    description: '＞ Specific claim details that you enter, submit or provide to us.'
                },
                {
                    description: '＞ IP address (automatically collected)'
                },
                {
                    description: '＞ Web browser type and version (automatically collected)'
                },
                {
                    description: '＞ Operating system (automatically collected)'
                },
                {
                    description: 'Minors - We only collect data from those under 18-years when necessary and in the provision of our service. In 100% of cases we will always deal with a responsible adult parent/relative or legal guardian.'
                },
            ]
    },
    {
        title: "Why do we collect and use your personal information?",
        description: [
            {
                text: 'We collect and use your personal information in order for us to provide you with our **Accident Management service**.'
            },
            {
                text: 'We may use your personal information to send marketing communications to you about similar products and services that may be of interest to you. We will send our marketing communications to you by email. It is to be noted that you can object to your personal information being used by us to send you marketing information. Each marketing email will also include an unsubscribe link to enable you to opt-out of receiving future marketing communications.'
            },
            {
                text: 'We may use your personal information for auditing and quality control purposes; allowing us to improve ULA’s future products and services.'
            },
            {
                text: 'When you communicate with us, we may keep this communication to improve our services alongside allowing us to respond to your enquiries and requests.'
            },
            {
                text: 'We may share aggregated information that contains no personal information to third parties.'
            },
        ]
    },
    {
        title: "What is our lawful basis for handling your personal information?",
        description: [
            {
                text: 'Under the General Data Protection Regulation (GDPR) we must have a lawful basis to legally handle your personal information.'
            },
            {
                text: 'The lawful basis that we rely upon are as follows:'
            },
            {
                text: '1. Negotiation of a sale or contract – to take steps, at your request, to enter you into a contract to fulfil your request and to perform our Accident management service;'
            },
            {
                text: '2. Consent - to call you and offer a regulated Claims Management Service, recommending a specialist law Firm or Solicitor;'
            },
            {
                text: '3. Legal obligation – to share personal information with law enforcement agencies and regulatory bodies if required to do so by law;'
            },
            {
                text: '4. Legitimate interests – to use your personal information to send you marketing communications about similar products/services in the future, to share your personal information with third party professional service providers that we engage and to share your personal information with our successor(s) in the event of a merger or acquisition.'
            }
        ]
    },
    {
        title: 'What happens if I do not provide my personal information?',
        description: 'We need your personal information in order to provide our **Accident Management Services** to you. If we do not collect your personal information we will not be able to provide our services to you.'
    },
    {
        title: 'Who will we share your personal information with?',
        description: [
            {
                text: 'We may need to contact your insurer if you have been involved in an accident.'
            },
            {
                text: 'If we are arranging vehicle hire/storage/repairs we may need to provide some of your personal details with our chosen providers for you to access and take full advantage of our service. We will always inform you prior to sharing your information with third party service providers.'
            },
            {
                text: 'This Policy applies only to the actions of United Legal Assistance Limited (ULA) and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.'
            },
            {
                text: 'We may engage the services of professional advisers such as compliance consultants, lawyers and accountants who may be given access to your personal information in order to provide their services to us. We will ensure that your personal information is protected by obtaining a written undertaking of confidentiality from the professional service providers.'
            },
            {
                text: 'In the event that we are subject to a merger or acquisition we may share your personal information with the organisation(s) that acquire or merge with our business and/or their professional advisers in the course of the acquisition or merger transaction and thereafter. In such circumstances the latter organisation will be required to use your personal information in the same ways described in this privacy policy.'
            },
        ]
    },
    {
        title: 'Third Party Websites & Services',
        description: 'This Website may, from time to time, provide links to other websites. ULA has no control over such websites and is in no way responsible for the content thereof. This Policy does not extend to your use of such websites. Users are advised to read the privacy policy or statement of other websites prior to using them.'
    },
    {
        title: 'How do we keep your data secure?',
        description: [
            {
                text: 'Data security is of great importance to ULA and to protect your data we have put in place suitable, realistic, physical, electronic and managerial procedures to safeguard and secure data collected via this Website. Therefore, we do our utmost to ensure that all reasonable steps are taken to make sure that your data is stored securely.'
            },
            {
                text: 'Unfortunately, the sending of information via the internet is not totally secure and on occasion such information can be intercepted. We cannot guarantee the security of the data that you choose to send us electronically and sending such information is entirely at your own risk.'
            },
            {
                text: 'All personal data is stored securely in accordance with the principles of the General Data Protection Regulation (GDPR) 2018.'
            },
        ]
    },
    {
        title: 'How long will you keep my data for?',
        description: [
            {
                text: 'We will store your personal information for up to six years from you ceasing to be our client. This is to enable us to refer to our records in the unlikely event that you want to lodge a complaint against us within statutory time limits.'
            },
            {
                text: 'If you do not exercise your right to object to marketing or unsubscribe to our marketing emails, we will store your personal information for marketing purposes until you exercise your right to object or unsubscribe.'
            },
        ]
    },
    {
        title: 'What are my Data Rights?',
        description: 'The GDPR provides the following rights for individuals:'
    },
    {
        description: [
            {
                subtitle: '1. Right to be informed',
                text: 'You have the right to be informed about how we use the information you have provide us with. This is covered within this Privacy Policy and is available to request.'
            },
            {
                subtitle: '2. Right of access',
                text: 'You have the right to access all personal data that we hold about you. To access such information, we request that you provide us with a Subject Access Request SAR). Please get in touch with us via the “contact us” page on our web site or via post.'
            },
            {
                subtitle: '3. Right to rectification',
                text: 'You are entitled to have personal data rectified if it is inaccurate or incomplete. We will respond within one month.'
            },
            {
                subtitle: '4. Right to erasure',
                text: 'You have the right to erasure in certain circumstances:'
            },
            {
                text: 'Where the personal data is no longer necessary in relation to the purpose for which it was originally collected/processed'
            },
            {
                text: 'When you withdraw consent'
            },
            {
                text: 'When you object to the processing and there is no overriding legitimate interest for continuing the processing'
            },
            {
                text: 'Your personal data must be erased in order to comply with a legal obligation'
            },
            {
                text: 'We may refuse to comply with a request for erasure in rare circumstances such as in the event of exercise or defence of legal claims.'
            },
            {
                subtitle: '5. Right to restrict processing',
                text: 'You have the right to block or suppress processing of your personal data. When processing is restricted we are permitted to store your personal data but not further process it. We will retain just enough information about you to enable that the restriction, as directed by you, is respected in the future.'
            },
            {
                subtitle: '6. Right to data portability',
                text: 'You have the right to request the movement, copy or transfer of your personal data easily from US to you or your requested destination, in a safe and secure manner.'
            },
            {
                subtitle: '7. Right to object',
                text: 'Processing based on legitimate interests or direct marketing.'
            },
            {
                subtitle: '8. Right to complain',
                text: 'You have the right to complain to us if you feel your data has been processed incorrectly, been misused or we have not met your data processing expectations. How to complain? Please see our complaints policy on this website.'
            },
        ]
    },
    {
        title: 'Changes to This Policy',
        description: 'ULA reserves the right to change this Policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website. Any substantial change affecting your processing rights, will be communicated directly to you as a customer of ULA.'
    },
    {
        title: 'How can I exercise my rights in relation to my personal information?',
        description: 'You can exercise all of your rights by contacting us on any of the above contact details.'
    },
    {
        title: 'How do I lodge a complaint about the use of my personal information?',
        description: [
            {
                text: 'You can lodge a complaint with us directly by contacting us on one of the above contact details.'
            },
            {
                text: 'You also have the right to lodge a complaint directly with the Information Commissioner’s Office (ICO). The ICO are the regulator who makes sure that we use your personal information in a lawful way.'
            },
            {
                text: 'You can lodge a complaint with the ICO by following this link [https://ico.org.uk/concerns/](https://ico.org.uk/concerns/) or calling the ICO on [0303 123 1113](tel:03031231113).'
            },
        ]
    }
]